import './../css/style.css';

console.log('hello world 3');
console.log('process.env.NODE_ENV', process.env.NODE_ENV);

// import * as OfflinePluginRuntime from 'offline-plugin/runtime';
// OfflinePluginRuntime.install();

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode! - no Google Analytics');
}

// const CGX = CGX || {};
const CGX = {};

CGX.Site = (function() {
  'use strict';

  // SETTINGS //
  const _s = {
    topMenuScroll: 10,
  };

  // VARS //
  // const _bodyTag = document.querySelector('body');
  // const _titleTag = document.querySelector('title');
  // const _siteTitleEl = document.querySelector('.site-title');
  // const _siteContentEl = document.querySelector('.site-content');
  // let _topMenu;
  // let _previousUrl;

  // FUNCTIONS //
  function _bindEvents() {
    // const _scrollEvent = window.addEventListener( 'scroll', _onScroll );
    document
      .getElementById('mobileToggle')
      .addEventListener('click', _toggleMobileNav);
  }

  function _init() {
    // _topMenu = document.querySelector('.site-title');
  }

  function _toggleMobileNav(e) {
    console.log('toggle mobile nav');
    const el = document.querySelector('.menu__list');
    console.log('el', el);
    el.classList.toggle('menu__list--open');
  }

  // function _onScroll() {
  //   var _scrollPos = this.pageYOffset;

  //   _scrollPos > _s.topMenuScroll
  //     ? _topMenu.classList.add('scrolled')
  //     : _topMenu.classList.remove('scrolled');
  // }

  ////////////
  // RETURN //
  ////////////
  return {
    init: function() {
      _bindEvents();
      _init();
    },
  };
})();

CGX.Helpers = (function() {
  'use strict';

  ///////////////
  // FUNCTIONS //
  ///////////////
  // function _bindEvents() {};
  // function _init() {};

  // Helper function
  function _delay(t) {
    return new Promise(function(resolve) {
      setTimeout(resolve, t);
    });
  }

  ////////////
  // RETURN //
  ////////////
  return {
    // init: function() {
    //   _bindEvents();
    //   _init();
    // },
    delay: _delay,
  };
})();

CGX.init = function() {
  'use strict';
  CGX.Site.init();
};

CGX.init();
